var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-8 pr-8"},[_c('div',{staticClass:"mb-xl-margin-file-sign"},[_c('v-row',{staticClass:"mt-10"},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"6"}},[_c('h1',{staticClass:"absence__title"},[_vm._v("LISTA DE AUSENCIAS")])])],1),_c('v-card',{staticClass:"absence__card px-8 mt-8",attrs:{"outlined":"","elevation":"0"}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"label":"Buscar"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":"#afb0b0"}},[_vm._v(" mdi-magnify ")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"mt-2 ml-2",staticStyle:{"color":"#fff"},style:({
                'background-color': hover ? '#324CA1' : '#466BE3',
              }),attrs:{"block":"","rounded":""},on:{"click":function($event){return _vm.download()}}},[_c('strong',[_vm._v(" Descargar ")])])]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0 absence__table",staticStyle:{"background-color":"#f9f9f900"},attrs:{"search":_vm.search,"loading":_vm.loading.table,"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"icon":"mdi-information-outline","prominent":"","text":"","type":"info"}},[_c('b',[_vm._v("No se encontraron resultados")])])]},proxy:true},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"icon":"mdi-information-outline","prominent":"","text":"","type":"info"}},[_c('b',[_vm._v("No se encontró información.")])])]},proxy:true},{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"#214484","height":5,"indeterminate":"","stream":""}})]},proxy:true},{key:"item.date",fn:function(ref){
              var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Fecha inicio: ")]),_vm._v(" "+_vm._s(item.nove.fec_inic)+" ")]),_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Fecha desde: ")]),_vm._v(" "+_vm._s(item.nove.fec_desd)+" ")]),_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Fecha hasta: ")]),_vm._v(" "+_vm._s(item.nove.fec_hast)+" ")])]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [(item.user.notification_absence.length == 0)?_c('v-chip',{attrs:{"color":"#466be3","outlined":"","small":""}},[_vm._v(" Pendiente ")]):_c('v-chip',{attrs:{"color":"#466be3","dark":"","small":""}},[_vm._v(" Notificado ")])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"max-width":"300","color":"#000","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":item.user.notification_absence.length > 0,"color":"#26019c","icon":""},on:{"click":function($event){return _vm.sendEmail(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-email")])],1)]}}],null,true)},[_c('span',[_vm._v(" Enviar notificación de ausencia "),_c('br'),_c('strong',[_vm._v("CORREO 1: ")]),_vm._v(" maria.barrios@brm.com.co "),_c('br'),_c('strong',[_vm._v("CORREO 2: ")]),_vm._v(" alejandro.cortes@brm.com.co "),_c('br'),_c('strong',[_vm._v("CORREO 3: ")]),_vm._v(" karen.castaneda@brm.com.co "),_c('br'),_c('strong',[_vm._v("CORREO 4: ")]),_vm._v(" horacio.molina@brm.com.co "),_c('br'),_c('strong',[_vm._v("CORREO 5: ")]),_vm._v(" Katherine.bermudez@brm.com.co "),_c('br'),_c('strong',[_vm._v("CORREO 6: ")]),_vm._v(" Javier.Fabra@brm.com.co "),_c('br'),_c('strong',[_vm._v("CORREO 7: ")]),_vm._v(" nestor.angel@brm.com.co "),_c('br'),_c('strong',[_vm._v("CORREO 8: ")]),_vm._v(" jenyfer.porras@brm.com.co ")])])]}}])})],1)],1)],1)],1),_c('Alert',{attrs:{"open":_vm.message.dialog,"text":_vm.message.sms,"title":_vm.message.title,"type":_vm.message.type,"redirect":_vm.message.redirect},on:{"close":function($event){_vm.message.dialog = false}}}),_c('v-overlay',{attrs:{"value":_vm.loadingAll}},[_c('v-progress-circular',{attrs:{"size":120,"color":"#466BE3","indeterminate":""}},[_vm._v(" "+_vm._s(_vm.$translate("general.loading"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }