<template>
  <div class="pl-8 pr-8">
    <div class="mb-xl-margin-file-sign">
      <v-row class="mt-10">
        <v-col class="text-left" cols="6">
          <h1 class="absence__title">LISTA DE AUSENCIAS</h1>
        </v-col>
      </v-row>
      <v-card outlined class="absence__card px-8 mt-8" elevation="0">
        <!-- Filtros -->
        <v-row class="mt-2">
          <v-col cols="8">
            <v-text-field v-model="search" label="Buscar">
              <template v-slot:prepend-inner>
                <v-icon color="#afb0b0"> mdi-magnify </v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-hover v-slot="{ hover }">
              <v-btn
                @click="download()"
                block
                rounded
                style="color: #fff"
                :style="{
                  'background-color': hover ? '#324CA1' : '#466BE3',
                }"
                class="mt-2 ml-2"
              >
                <strong> Descargar </strong>
              </v-btn>
            </v-hover>
          </v-col>
        </v-row>
        <!-- Tabla -->
        <v-row>
          <v-col cols="12">
            <v-data-table
              :search="search"
              :loading="loading.table"
              style="background-color: #f9f9f900"
              :headers="headers"
              :items="items"
              class="elevation-0 absence__table"
            >
              <!-- Sin resultados de busqueda -->
              <template v-slot:no-results>
                <v-alert
                  icon="mdi-information-outline"
                  prominent
                  text
                  type="info"
                >
                  <b>No se encontraron resultados</b>
                </v-alert>
              </template>
              <!-- Sin Data -->
              <template class="pl-0 pr-0" v-slot:no-data>
                <v-alert
                  icon="mdi-information-outline"
                  prominent
                  text
                  type="info"
                >
                  <b>No se encontró información.</b>
                </v-alert>
              </template>
              <!-- Loading -->
              <template v-slot:progress>
                <v-progress-linear
                  color="#214484"
                  :height="5"
                  indeterminate
                  stream
                ></v-progress-linear>
              </template>
              <template v-slot:item.date="{ item }">
                <p class="mb-0"><strong>Fecha inicio: </strong> {{item.nove.fec_inic}} </p>
                <p class="mb-0"><strong>Fecha desde: </strong> {{item.nove.fec_desd}} </p>
                <p class="mb-0"><strong>Fecha hasta: </strong> {{item.nove.fec_hast}} </p>
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip color="#466be3" outlined  v-if="item.user.notification_absence.length == 0" small> Pendiente </v-chip>
                <v-chip color="#466be3" dark v-else small> Notificado </v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip max-width="300" color="#000" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn :disabled="item.user.notification_absence.length > 0" color="#26019c" @click="sendEmail(item)" v-bind="attrs" v-on="on" icon>
                      <v-icon> mdi-email</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Enviar notificación de ausencia <br />
                    <strong>CORREO 1: </strong> maria.barrios@brm.com.co
                    <br />
                    <strong>CORREO 2: </strong> alejandro.cortes@brm.com.co
                    <br />
                    <strong>CORREO 3: </strong> karen.castaneda@brm.com.co
                    <br />
                    <strong>CORREO 4: </strong> horacio.molina@brm.com.co
                    <br />
                    <strong>CORREO 5: </strong> Katherine.bermudez@brm.com.co
                    <br />
                    <strong>CORREO 6: </strong> Javier.Fabra@brm.com.co
                    <br />
                    <strong>CORREO 7: </strong> nestor.angel@brm.com.co
                    <br />
                    <strong>CORREO 8: </strong> jenyfer.porras@brm.com.co

                  </span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      :redirect="message.redirect"
      @close="message.dialog = false"
    >
    </Alert>
    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import color from "@/mixins/color";
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Alert,
  },
  mixins: [color],
  data() {
    return {
      origin: window.location.origin,
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
      loading: {
        table: false,
      },
      items: [ ],
      headers: [
        {
          text: "Nombre",
          value: "user.full_name",
          class: "absences__header",
        },
        {
          text: "Documento",
          value: "user.document_number",
          class: "absences__header",
        },
        {
          text: "Campaña",
          value: "user.campaign",
          class: "absences__header",
        },
        {
          text: "Tipo de ausencia",
          value: "nove.concepto.nom_conc",
          class: "absences__header",
        },
         {
          text: "Fechas",
          value: "date",
          class: "absences__header",
        },
        {
          text: "Estado",
          value: "status",
          class: "absences__header",
        },
        {
          text: "Acciones",
          value: "actions",
          class: "absences__header",
        },
        // { text: "Documento", value: "file" },
      ],
      listStatus: [
        {
          id: 5,
          name: "Pendiente",
        },
        {
          id: 8,
          name: "En proceso",
        },
        {
          id: 4,
          name: "Completado",
        },
        {
          id: 3,
          name: "Expirado",
        },
        {
          id: 6,
          name: "Error",
        },
      ],
      listBonus: [],
      listProfile: [],
      loadingAll: false,
      token: "",
      infoToken: "",
      name: "",
      statusId: "",
      search: "",
      pabsence: true,
    };
  },
  watch: {},
  computed: {},
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");

      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getabsence() {
      this.items = [];
      this.loadingAll = true;
      var data = {
        statusId: this.statusId,
        personalabsence: this.pabsence,
      };
      Api.LaborRelations()
        .getAbsence(this.token, data)
        .then((res) => {
          this.items = res.data.data;
          for (let i = 0; i < this.items.length; i++) {
            this.items[i].nove.fec_inic = new Date(this.items[i].nove.fec_inic).toISOString().substr(0, 10);
            this.items[i].nove.fec_desd = new Date(this.items[i].nove.fec_desd).toISOString().substr(0, 10);
            this.items[i].nove.fec_hast = new Date(this.items[i].nove.fec_hast).toISOString().substr(0, 10);
            this.items[i].user.full_name =  (this.items[i].user.full_name == null) ?  null : this.toTitleCase(this.items[i].user.full_name);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    download() {
      // var data = {
      //   statusId: this.statusId,
      //   personalabsence: this.pabsence,
      // };
      this.loadingAll = true;
      Api.Security()
        .getReportAbsenceDownload()
        .then((res) => {
          if (res.data.cod == 0) {
            this.message.dialog = true;
            this.message.sms = res.data.message;
            this.message.title = "Excelente";
            this.message.type = "success";
            window.open(res.data.data, "_blank");
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.message.dialog = true;
          if (error.response.status == 404) {
            this.message.sms =
              "Aun no se han cargado condiciones de comisiones";
            this.message.title = "¡Oh no!";
            this.message.type = "error";
          } else {
            this.message.sms = error.response.data.message;
            this.message.title = "¡Oh no!";
            this.message.type = "error";
          }
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    getColorStatus(status) {
      var color = this.color(status);
      return color;
    },
    getApps(allApps) {
      if (allApps && allApps.length !== 0) {
        for (let i = 0; i < allApps.length; i++) {
          if (allApps[i]._ID == "324") {
            this.pabsence = false;
          }
        }
      }
    },
    sendEmail(data) {
      console.log(data)
      this.loadingAll = true;
      Api.Security()
        .sendNotification(this.token, data)
        .then((res) => {
          this.getabsence();
          this.message.dialog = true;
          this.message.title = "Excelente";
          this.message.sms = res.data.message;
          this.message.type = "success";
          this.message.redirect = "";
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    toTitleCase(str) {
      if(str == null){
        return null;
      }
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
  },
  created() {
    this.getToken();
  },
  mounted() {
    this.getabsence();
  },
};
</script>
<style>
@import "./../../assets/css/main.less";
.absence__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 I !important;
}
tr,
th,
span .absence__table .text-start .sortable {
  font-size: 16px !important;
}

.absence__card {
  border-radius: 15px !important;
}

.absence__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.absences__header {
  color: #353535 !important;
}
</style>
